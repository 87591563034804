

export default function Footer() {
    return (
        <footer className="bg-white">
            <div className="mx-auto max-w-7xl px-6 py-12 md:flex md:items-center md:justify-between lg:px-8">

                <div className="mt-8 md:order-1 md:mt-0">
                    <p className="text-left text-xs leading-5 text-gray-500">
                        Contact Jake Flynn for A League questions
                    </p>
                    <p className="text-left text-xs leading-5 text-gray-500">
                        Phone: <a className={"mailto-link"} href="tel:509-669-1331">509-669-1331</a>
                    </p>
                    <p className="text-left text-xs leading-5 text-gray-500">
                        Email: <a className={"mailto-link"} href="mailto:jake@flynn.dev">jake@flynn.dev</a>
                    </p>

                </div>

                <div className="mt-8 md:order-1 md:mt-0">
                    <p className="text-left text-xs leading-5 text-gray-500">
                        Contact Keith Kistler for Coed Open League questions
                    </p>
                    <p className="text-left text-xs leading-5 text-gray-500">
                        Phone: <a className={"mailto-link"} href="tel:509-449-1634">509-449-1634</a>
                    </p>
                    <p className="text-left text-xs leading-5 text-gray-500">
                        Email: <a className={"mailto-link"} href="mailto:kistlerkeith@gmail.com">kistlerkeith@gmail.com</a>
                    </p>

                </div>
            </div>
        </footer>
    )
}