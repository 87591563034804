import Header from "../components/header"
import MainContent from '../components/mainContent'
import SignupForm from "../components/signupForm";


function Main() {


    return(
        <>
            <Header text={"Wenatchee Mens Hockey"}/>
            <MainContent/>
            {/*<SignupForm/>*/}

        </>
    )
}

export default Main;