import Header from "../components/header"
import {InformationCircleIcon} from "@heroicons/react/20/solid";
import PlayerStatTable from "../components/playerStatTable";
import TeamsStatTable from "../components/teamStatsTable";

function Stats() {


    return(
        <div className="pb-8">
            <Header text={"Stats"}/>
            <div className="flex gap-x-3 info-margin" >
                <InformationCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                <span>
                    Player and Team stats. Currently for the 2024 fall session, working on getting additional sorting and previous session views.
                </span>
            </div>
            <TeamsStatTable/>
            <PlayerStatTable/>
        </div>
    )
}

export default Stats;