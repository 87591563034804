

import {API} from "aws-amplify";
import {useState, useEffect} from "react";


export default function PlayerStatTable() {

    const [players, setPlayers] = useState([])

    const myInit = {
        headers: {}, // OPTIONAL
        response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
        queryStringParameters: {
        }
    };

    useEffect( () => {
        async function getData() {
            const results = await API.get("hockey", "/points", myInit);
            console.log(results)
            setPlayers(results.data.points);

        }
        getData();

    }, [])


    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Player Stats</h1>
                </div>

            </div>
            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                    #
                                </th>
                                <th scope="col" className="py-3.5 pl-4 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                    Name
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                    Games Played
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                    Goals
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                    Assists
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-center highlight-background text-sm font-semibold text-gray-900">
                                    Points
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                    Pts/G
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                    Pims
                                </th>
                            </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                            {players.map((person, index) => (
                                <tr key={`${person.first_name}-${person.last_name}-${index}`}>
                                    <td className="whitespace-nowrap py-4 pl-4 text-sm font-medium text-gray-900 sm:pl-0">{index + 1}</td>
                                    <td className="whitespace-nowrap py-4 pl-4 text-sm font-medium text-gray-500">{person.first_name} {person.last_name}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500">{person.games_played}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500">{person.goals}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500">{person.assists}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm highlight-background text-center text-gray-500">{person.points}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500">{(person.points / (person.games_played || 1)).toFixed(2)}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500">{person.pims}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}