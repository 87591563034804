import { Disclosure } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom';
import { useState } from "react";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export default function Nav() {

    const [navigation, setNavigation] = useState([
        { name: 'A League', href: '/', current: true },
        { name: 'COED Open League', href: '/open-league', current: false },
        { name: 'Schedule', href: '/schedule', current: false },
        { name: 'Rosters', href: '/rosters', current: false },
        { name: 'Stats', href: '/stats', current: false },
        { name: 'Signups', href: '/signups', current: false },

    ]);

    const handleLinkClick = (index, close) => {
        let tempNav = [
            { name: 'A League', href: '/', current: false },
            { name: 'COED Open League', href: '/open-league', current: false },
            { name: 'Schedule', href: '/schedule', current: false },
            { name: 'Rosters', href: '/rosters', current: false },
            { name: 'Stats', href: '/stats', current: false },
            { name: 'Signups', href: '/signups', current: false },

        ];
        console.log(index);
        console.log(tempNav);
        tempNav[index].current = true;

        setNavigation(tempNav);

        close();
    }

    return (
        <Disclosure as="nav" className="bg-gray-800">
            {({ open, close }) => (
                <>
                    <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                        <div className="relative flex h-16 items-center justify-between">
                            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                {/* Mobile menu button*/}
                                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                    <span className="absolute -inset-0.5" />
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </Disclosure.Button>
                            </div>
                            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                                <div className="hidden sm:ml-6 sm:block">
                                    <div className="flex space-x-4">
                                        {navigation.map((item, index) => (
                                            <Link
                                                key={item.name}
                                                to={item.href}
                                                onClick={() => handleLinkClick(index, close)}
                                                aria-current={item.current ? 'page' : undefined}
                                                className={classNames(
                                                    item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                    'rounded-md px-3 py-2 text-sm font-medium'
                                                )}>
                                                {item.name}
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <Disclosure.Panel className="sm:hidden">
                        <div className="space-y-1 px-2 pb-3 pt-2 flex flex-col">
                            {navigation.map((item, index) => (
                                <Link
                                    key={item.name}
                                    to={item.href}
                                    onClick={() => handleLinkClick(index, close)}
                                    aria-current={item.current ? 'page' : undefined}
                                    className={classNames(
                                    item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                    'rounded-md px-3 py-2 text-sm font-medium'
                                )}>
                                    {item.name}
                                </Link>
                            ))}
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}