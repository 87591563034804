import {UserPlusIcon, ClipboardIcon, CalendarDaysIcon, ClipboardDocumentListIcon, ScaleIcon, UserGroupIcon, ArrowPathIcon, UserIcon, ExclamationCircleIcon, ClockIcon, CurrencyDollarIcon } from '@heroicons/react/20/solid'

export default function OpenLeagueMainContent() {
    return (
        <div className="relative isolate overflow-hidden bg-white px-6 py-16 sm:py-16 lg:overflow-visible lg:px-0">
            <div className="absolute inset-0 -z-10 overflow-hidden">
                <svg
                    className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
                    aria-hidden="true"
                >
                    <defs>
                        <pattern
                            id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                            width={200}
                            height={200}
                            x="50%"
                            y={-1}
                            patternUnits="userSpaceOnUse"
                        >
                            <path d="M100 200V.5M.5 .5H200" fill="none" />
                        </pattern>
                    </defs>
                    <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
                        <path
                            d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                            strokeWidth={0}
                        />
                    </svg>
                    <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
                </svg>
            </div>
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
                <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                    <div className="lg:pr-4">
                        <div className="lg:max-w-lg">
                            {/*<p className="text-base font-semibold leading-7 text-indigo-600">Deploy faster</p>*/}
                            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">COED Open League</h1>
                            <p className="mt-6 text-xl leading-8 text-gray-700">
                                Welcome to Greater Wenatchee COED hockey. A place for all skill levels to enjoy the game of hockey. Please fill
                                out the sign up form at the bottom of the page to be in the upcoming draft. Payment
                                is not required at this time, this only confirms your intent to play.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
                    <img
                        className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
                        src="https://images.unsplash.com/photo-1545471977-94cac22e71ed?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3870&q=80"
                        alt=""
                    />
                </div>
                <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                    <div className="lg:pr-4">
                        <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
                            <p>
                                The fall 2023 season will start on October 1st, and end on the week of December 10th.  No games Thanksgiving weekend (11/26)
                            </p>
                            <ul role="list" className="mt-8 space-y-8 text-gray-600">

                                <li className="flex gap-x-3">
                                    <UserPlusIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                    <span>
                                        <strong className="font-semibold text-gray-900">Sign up.</strong> Below you will find a sign up form. please fill out the form
                                        to be entered into the upcoming draft. Please make sure to specify your main and secondary positions, this will help the Captains
                                        to draft their teams. If you are an all or nothing kinda person, then feel free to select the same position for both fields.
                                    </span>
                                </li>

                                <li className="flex gap-x-3">
                                    <CurrencyDollarIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                    <span>
                                        <strong className="font-semibold text-gray-900">Payment.</strong> This will depend on the number of players that sign up, referee,
                                        ice time and timekeeper fees.  By signing up below you are not obligated to play if you decide to change your mind.  Final rosters
                                        will be made based on paid players.
                                    </span>
                                </li>

                                <li className="flex gap-x-3">
                                    <ScaleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                    <span>
                                        <strong className="font-semibold text-gray-900">Other League Options.</strong> There are two leagues this year, A and Open
                                        Division (this one).  If you are a high-level hockey player, you can play in either divisions. B and C level players should
                                        consider playing in the open division.
                                    </span>
                                </li>

                                <li className="flex gap-x-3">
                                    <UserIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                    <span>
                                        <strong className="font-semibold text-gray-900">Captain and Co-Captain.</strong> Each team will be lead by a Captain,
                                        and Co-Captain. These will be the people that oversee finding subs if needed, communicating with officials during call disputes,
                                        and any team management needs.
                                    </span>
                                </li>

                                <li className="flex gap-x-3">
                                    <UserGroupIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                    <span>
                                        <strong className="font-semibold text-gray-900">Package deal.</strong> We want to do our best to accommodate players that would
                                        like or need to play together or not play together. In the notes section of the signup form, please specify if there are any requests
                                        you may have (ex. father and son wish to be a package deal, brother and brother do not wish to be a package deal, Player A will be player
                                        B's ride to the rink and need to be a package, etc... ).
                                    </span>
                                </li>

                                <li className="flex gap-x-3">
                                    <ArrowPathIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                    <span>
                                        <strong className="font-semibold text-gray-900">Subs for missing players.</strong> We want teams to have the ability to either pick up
                                        a sub for a missing player, or not, ultimately that will be up to each captain. Although to make this fair, we will require subs be picked
                                        up in a 1 for 1 trade. Meaning if you are missing 3 players, you cannot pick up a single player stating that the single player is worth the
                                        3 missing players added together. The opposing teams captain must sign off on all subs, and subs can only be other players in the league.
                                    </span>
                                </li>

                                <li className="flex gap-x-3">
                                    <CalendarDaysIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                    <span>
                                        <strong className="font-semibold text-gray-900">Schedule.</strong> Games will be on Sundays at 6 PM and 730 pm. Each week you will play
                                        at one of those times. The season will include 8 weeks of regular play, semifinal, and final games. (schedule page coming soon)
                                    </span>
                                </li>

                                <li className="flex gap-x-3">
                                    <ClipboardDocumentListIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                    <span>
                                        <strong className="font-semibold text-gray-900">Score Keeping.</strong> Any and all volunteers are appreciated, and we plan to provide
                                        compensation. Please direct all inquiries to Keith Kistler. Via email at <a className="mailto-link" href="mailto:kistlerkeith@gmail.com">kistlerkeith@gmail.com</a>.
                                    </span>
                                </li>

                                <li className="flex gap-x-3">
                                    <ExclamationCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                    <span>
                                        <strong className="font-semibold text-gray-900">Referees.</strong> We have historically had issues finding referees.
                                        With that in mind, we will have a zero tolerance policy for abusing referees. This does not mean you cannot challenge
                                        a call or ask for clarification from them during a game. But please do so with some tact. While we will do our best
                                        to settle any disputes and address any issues, at the end of the day, refs are hard to find, while skaters are not.
                                        So please use some common sense and have some decorum when discussing issues with the refs.
                                    </span>
                                </li>

                            </ul>

                            {/*<h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">No server? No problem.</h2>*/}
                            {/*<p className="mt-6">*/}
                            {/*    Id orci tellus laoreet id ac. Dolor, aenean leo, ac etiam consequat in. Convallis arcu ipsum urna nibh.*/}
                            {/*    Pharetra, euismod vitae interdum mauris enim, consequat vulputate nibh. Maecenas pellentesque id sed*/}
                            {/*    tellus mauris, ultrices mauris. Tincidunt enim cursus ridiculus mi. Pellentesque nam sed nullam sed diam*/}
                            {/*    turpis ipsum eu a sed convallis diam.*/}
                            {/*</p>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}