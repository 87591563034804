import Header from "../components/header"
import Calendars from "../components/calendar"
import {season1} from '../schedules/season'
import {InformationCircleIcon} from "@heroicons/react/20/solid";
function Schedule() {




    return(
        <>
            <Header text={"Schedule"}/>
            <div className="flex gap-x-3 info-margin" >
                <InformationCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                <span>
                Schedule for the 2024 fall session.
                </span>
            </div>
            <Calendars events={season1}></Calendars>
        </>
    )
}

export default Schedule;