import {useState, useEffect} from "react";
import {API} from "aws-amplify";

const rosters = [
    {
        id: 1,
        name: 'Team 1',
        captain: "Captain Name",
        coCaptain: "Co Captain Name",
        players: ["Player Name", "Player Name", "Player Name", "Player Name", "Player Name", "Player Name", "Player Name", "Player Name", "Player Name", "Player Name", "Player Name", "Player Name", "Player Name", "Goalie Name"],

    },
    {
        id: 2,
        name: 'Team 2',
        captain: "Captain Name",
        coCaptain: "Co Captain Name",
        players: ["Player Name", "Player Name", "Player Name", "Player Name", "Player Name", "Player Name", "Player Name", "Player Name", "Player Name", "Player Name", "Player Name", "Player Name", "Player Name", "Goalie Name"],
    },
    {
        id: 3,
        name: 'Team 3',
        captain: "Captain Name",
        coCaptain: "Co Captain Name",
        players: ["Player Name", "Player Name", "Player Name", "Player Name", "Player Name", "Player Name", "Player Name", "Player Name", "Player Name", "Player Name", "Player Name", "Player Name", "Player Name", "Goalie Name"],
    },
    {
        id: 4,
        name: 'Team 4',
        captain: "Captain Name",
        coCaptain: "Co Captain Name",
        players: ["Player Name", "Player Name", "Player Name", "Player Name", "Player Name", "Player Name", "Player Name", "Player Name", "Player Name", "Player Name", "Player Name", "Player Name", "Player Name", "Goalie Name"],
    },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function PlayersSignups() {
    const [aSignups, setASignups] = useState([]);
    const [openSignups, setOpenSignups] = useState([])

    const myInit = {
        headers: {}, // OPTIONAL
        response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
        queryStringParameters: {
        }
    };

    useEffect( () => {
        async function getData() {
            const results = await API.get("hockey", "/getsignups", myInit);

            setASignups(results.data.aLeague);
            setOpenSignups(results.data.openLeague);
        }
        getData();

    }, [])

    return (
        <ul role="list" className="grid grid-cols-1 gap-x-6 gap-y-8 mb-8 lg:grid-cols-3 xl:gap-x-8 side-margins">

            <li key={"a-league"} className="overflow-hidden rounded-xl border border-gray-200">
                <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">

                    <div className="text-sm font-medium leading-6 text-gray-900">A League signups - {aSignups.length} singed up</div>

                </div>

                <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">


                    {aSignups.map((player, index) => (
                        <div className="flex justify-between gap-x-4 py-3" key={`${player.first_name}-${player.last_name}-${index}-A`}>
                            <dt className="text-gray-500">{player.first_name} {player.last_name}</dt>
                            <dd className="text-gray-700">

                            </dd>
                        </div>
                    ))}

                </dl>

            </li>

            <li key={"open league"} className="overflow-hidden rounded-xl border border-gray-200">
                <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">

                    <div className="text-sm font-medium leading-6 text-gray-900">Open League signups</div>

                </div>

                <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">


                    {openSignups.map((player, index) => (
                        <div className="flex justify-between gap-x-4 py-3" key={`${player.first_name}-${player.last_name}-${index}-Open`}>
                            <dt className="text-gray-500">{player.first_name} {player.last_name}</dt>
                            <dd className="text-gray-700">

                            </dd>
                        </div>
                    ))}

                </dl>

            </li>

        </ul>
    )
}