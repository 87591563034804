
//import './App.css';
//import  {Amplify, API, Auth} from 'aws-amplify'
import  {Amplify, Auth} from 'aws-amplify'
import '@aws-amplify/ui-react/styles.css';
//import { useAuthenticator, withAuthenticator } from '@aws-amplify/ui-react';
import awsconfig from './aws-exports';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';

//import partials
import Nav from './components/nav';
import Footer from "./components/footer";
import ScrollToTop from "./components/scrollToTop";

//import pages
import Main from './pages/main';
import Schedule from './pages/schedule';
import Rosters from './pages/rosters';
import Stats from './pages/stats';
import OpenLeague from "./pages/openLeague";
import Signups from "./pages/singups";
import NotFound from "./pages/notfound";


Amplify.configure(awsconfig);
Auth.configure(awsconfig);

function App() {
    //for use with authenticator
    // const { signOut } = useAuthenticator()
    return (
    <BrowserRouter>
        <>
            <ScrollToTop />
            <div className="App mx-auto max-w-7xl sm:px-6 lg:px-8">
                <Nav/>
                <Routes>
                    <Route path="/" element={<Main/>} />
                    <Route path="/schedule" element={<Schedule/>} />
                    <Route path="/rosters" element={<Rosters/>} />
                    <Route path="/stats" element={<Stats/>} />
                    <Route path="/open-league" element={<OpenLeague/>} />
                    <Route path="/signups" element={<Signups/>} />
                    <Route path="*" element={<NotFound/>} />
                </Routes>
                <Footer/>
            </div>

        </>
    </BrowserRouter>
    );
}

//for use with authenticator
//export default withAuthenticator(App);
export default App;
