import Header from "../components/header"
import OpenLeagueMainContent from "../components/openLeagueMainContent";
import OpenLeagueSignupForm from "../components/openSignupForm";


function OpenLeague() {


    return(
        <>
            <Header text={"COED Open League"}/>
            <OpenLeagueMainContent/>
            <OpenLeagueSignupForm/>
        </>
    )
}

export default OpenLeague;