
import { useState } from 'react'
import axios from 'axios';
import { API } from 'aws-amplify';
import Modal from './modal'



export default function OpenLeagueSignupForm() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [mainPosition, setMainPosition] = useState('Forward');
    const [secondaryPosition, setSecondaryPosition] = useState('Forward');
    const [notes, setNotes] = useState('');

    const [openModal, setOpenModal] = useState(false);
    const [modalText, setModalText] = useState("Thank you for signing up! Captains will be contacting everyone after the draft. You can view all players currently signed up for each league on the signups page.")

    const onSubmit = async () => {

        const payload = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone,
            mainPosition: mainPosition,
            secondaryPosition: secondaryPosition,
            notes: notes
        }

        const myInit = {
            body: payload
        };

        setOpenModal(true);

        await API.post("hockey", "/opensignup", myInit);

        cleanupForm();

    }

    const cleanupForm = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setMainPosition('Forward');
        setSecondaryPosition('Forward');
        setNotes('');
    }



    return (
        <div className="isolate bg-white px-6 py-8 sm:py-8 lg:px-8">
            <Modal text={modalText} open={openModal} />
            <div className="mx-auto max-w-2xl text-center">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Sign up for Open League</h2>
                <p className="mt-2 text-lg leading-8 text-gray-600">
                    No payment is required to sign up initially, this sign up is to determine how many teams we will be able to make.
                </p>
                <p className="mt-2 text-lg leading-8 text-gray-600">
                    Please fill out the following form to register your intention for the upcoming season.
                    Make sure to put in the notes section any special requests, such as needing to be a package pick
                    with another player.
                </p>
            </div>
            <form onSubmit={onSubmit} className="mx-auto mt-16 max-w-xl sm:mt-20">
                <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                    <div>
                        <label htmlFor="firstName" className="block text-sm font-semibold leading-6 text-gray-900">
                            First name
                        </label>
                        <div className="mt-2.5">
                            <input
                                type="text"
                                name="firstName"
                                value={firstName}
                                onChange={e => setFirstName(e.target.value)}
                                id="firstName"
                                autoComplete="given-name"
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="lastName" className="block text-sm font-semibold leading-6 text-gray-900">
                            Last name
                        </label>
                        <div className="mt-2.5">
                            <input
                                type="text"
                                name="lastName"
                                id="lastName"
                                value={lastName}
                                onChange={e => setLastName(e.target.value)}
                                autoComplete="family-name"
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div className="sm:col-span-2">
                        <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                            Email
                        </label>
                        <div className="mt-2.5">
                            <input
                                type="email"
                                name="email"
                                id="email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                autoComplete="email"
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="phone" className="block text-sm font-semibold leading-6 text-gray-900">
                            Phone number
                        </label>
                        <div className="relative mt-2.5">

                            <input
                                type="tel"
                                name="phone"
                                id="phone"
                                value={phone}
                                onChange={e => setPhone(e.target.value)}
                                autoComplete="tel"
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div className="sm:col-span-2">
                        <label htmlFor="position1" className="block text-sm font-medium leading-6 text-gray-900">
                            Main Position
                        </label>
                        <select
                            id="position1"
                            name="position1"
                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            value={mainPosition}
                            onChange={e => setMainPosition(e.target.value)}
                        >
                            <option>Forward</option>
                            <option>Defense</option>
                            <option>Goalie</option>
                        </select>
                    </div>

                    <div className="sm:col-span-2">
                        <label htmlFor="position2" className="block text-sm font-medium leading-6 text-gray-900">
                            Secondary Position
                        </label>
                        <select
                            id="position2"
                            name="position2"
                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            value={secondaryPosition}
                            onChange={e => setSecondaryPosition(e.target.value)}
                        >
                            <option>Forward</option>
                            <option>Defense</option>
                            <option>Goalie</option>
                        </select>
                    </div>


                    <div className="sm:col-span-2">
                        <label htmlFor="notes" className="block text-sm font-semibold leading-6 text-gray-900">
                            Notes
                        </label>
                        <div className="mt-2.5">
                              <textarea
                                  name="notes"
                                  id="notes"
                                  value={notes}
                                  onChange={e => setNotes(e.target.value)}
                                  rows={4}
                                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"

                              />
                        </div>
                    </div>

                </div>
                <div className="mt-10">
                    <button
                        type="button"
                        onClick={onSubmit}
                        className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        Sign me up!
                    </button>
                </div>
            </form>
        </div>
    )
}