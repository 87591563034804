import React, { useState, useCallback } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'moment-timezone'
import Modal from './modal'
import Toolbar from "react-big-calendar/lib/Toolbar";
//import "react-big-calendar/lib/css/react-big-calendar.css";



export default function Calendars(props) {

    moment.tz.setDefault('America/Los_Angeles')
    const localizer = momentLocalizer(moment);

    const [openModal, setOpenModal] = useState(false);
    const [modalText, setModalText] = useState("modal default")

    const onSelectEvent = useCallback((calEvent) => {

        //console.log(calEvent);
        setModalText(`${calEvent.title} on ${moment(calEvent.start).format("dddd, MMMM Do YYYY, h:mm a")}`);
        setOpenModal(true);


    }, [])

    const handleChange = () => {
        console.log("this block code executed");
    };


    const events = props.events

    let change = handleChange();
    return (

            <div className={'pad-cal side-margins'}>
                <Modal text={modalText} open={openModal} />
                <Calendar
                    localizer={localizer}
                    defaultDate={new Date()}
                    defaultView="month"
                    events={events}
                    style={{ height: "100vh" }}
                    onSelectEvent={onSelectEvent}
                    drilldownView={null}
                    components={{
                        toolbar: CustomToolbar({ events, change })
                    }}
                />
            </div>

    )
}


var CustomToolbar = ({ handleChange }) => {
    return class BaseToolBar extends Toolbar {
        constructor(props) {
            super(props);
        }
        handleDayChange = (event, mconte) => {
            mconte(event.target.value);
        };
        handleNamvigate = (detail, elem) => {
            detail.navigate(elem);
        };
        render() {
            return (
                <div className="posr">

                    <div className="rbc-btn-group">
                        <div className="view-label">View type: </div>
                        <select
                            className="form-control"
                            onChange={(e) => this.handleDayChange(e, this.view)}
                            defaultValue={"month"}
                        >
                            {/*<option className="optionbar" value="day">*/}
                            {/*    Day*/}
                            {/*</option>*/}
                            {/*<option className="optionbar" value="week">*/}
                            {/*    Week*/}
                            {/*</option>*/}
                            <option className="optionbar" value="month">
                                Month
                            </option>
                            <option className="optionbar" value="agenda">
                                Agenda
                            </option>
                        </select>
                    </div>

                    <div className="rbc-btn-group cal-nav-button-holder">
                        <button
                            type="button"
                            className="nextp-btn custom-calendar-button"
                            onClick={() => this.handleNamvigate(this, "PREV")}
                        >
                            Prev
                        </button>
                        <button
                            type="button"
                            className="defaultbtn custom-calendar-button"
                            onClick={() => this.handleNamvigate(this, "TODAY")}
                        >
                            Today
                        </button>
                        <button
                            type="button"
                            className="nextp-btn custom-calendar-button"
                            onClick={() => this.handleNamvigate(this, "NEXT")}
                        >
                            Next
                        </button>
                    </div>

                    <div className="rbc-toolbar-label">{this.props.label}</div>
                </div>
            );
        }
    };
};