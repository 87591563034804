import Header from "../components/header"
import PlayersSignups from "../components/playersSignups";
import {InformationCircleIcon} from "@heroicons/react/20/solid";

function Signups() {


    return(
        <>
            <Header text={"Current Signups"}/>
            <div className="flex gap-x-3 info-margin" >
                <InformationCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                <span>
                Below are the current players we have signed up for each league. Please check to make sure your name is in the signup for the league you wish to play in, note, you can sign up for both if you would like.
                    If you do not see your name, contact Jake Flynn by phone at 509-669-1331 or email at <a className={"mailto-link"} href="mailto:jake@flynn.dev">jake@flynn.dev</a>
                </span>
            </div>
            <PlayersSignups/>
        </>
    )
}

export default Signups;